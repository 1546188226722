var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative w-full pb-full bg-gray-300"},[_vm._l((_vm.images),function(image,index){return _c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(image),expression:"image"}],key:("image-" + index),staticClass:"object-cover absolute h-full w-full inset-0 transition-all duration-300 ease-in-out",class:{
      'opacity-100': index === _vm.currentIndex,
      'opacity-0': index !== _vm.currentIndex
    },attrs:{"alt":_vm.title}})}),(_vm.images.length > 1)?_c('div',{staticClass:"absolute z-10 w-full h-full pb-4 flex flex-col items-center justify-end"},[_c('div',{staticClass:"w-full h-full flex justify-between items-center px-4 transition-opacity duration-300 ease-in-out opacity-0 hover:opacity-100"},[_c('div',{staticClass:"bg-white w-8 h-8 rounded-full flex items-center justify-center transition-opacity duration-300 ease-in-out opacity-70 hover:opacity-100 shadow-sm",attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.changeSlide(-1)}}},[_c('b-icon',{attrs:{"name":"chevron-left"}})],1),_c('div',{staticClass:"bg-white w-8 h-8 rounded-full flex items-center justify-center transition-opacity duration-300 ease-in-out opacity-70 hover:opacity-100 shadow-sm",attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.changeSlide(1)}}},[_c('b-icon',{attrs:{"name":"chevron-right"}})],1)]),_c('div',{staticClass:"flex items-center"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex > 3),expression:"currentIndex > 3"}],staticClass:"bg-white rounded-full h-3 w-3 opacity-50 mr-2 shadow-sm"}),_vm._l((_vm.images),function(slide,index){return _c('span',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.currentIndex > 2
            ? index >= _vm.currentIndex - 2 && index <= _vm.currentIndex
            : index < 3
        ),expression:"\n          currentIndex > 2\n            ? index >= currentIndex - 2 && index <= currentIndex\n            : index < 3\n        "}],key:("slide-" + index),staticClass:"bg-white rounded-full w-4 h-4 shadow-sm transition duration-300 ease-in-out",class:[
          {
            'opacity-50': index !== _vm.currentIndex,
            'mr-2': index !== _vm.images.length - 1
          }
        ]})}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex !== _vm.images.length - 1 && _vm.images.length > 3),expression:"currentIndex !== images.length - 1 && images.length > 3"}],staticClass:"bg-white rounded-full h-3 w-3 opacity-50 shadow-sm"})],2)]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }