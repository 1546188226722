<template>
  <button
    v-bind="$attrs"
    class="px-4 py-2 | w-auto | transition-shadow duration-200 shadow-sm hover:shadow-md | inline-flex justify-center items-center | rounded-md | border | text-base sm:text-sm | font-medium | focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    :class="[
      {
        'bg-gray-900 text-white border-black':
          variant === 'primary' && !disabled
      },
      {
        'bg-white hover:bg-gray-5 text-gray-600 hover:text-gray-700':
          variant === 'secondary' && !disabled
      },
      {
        'bg-gray-200 text-gray-400 line-through cursor-not-allowed': disabled
      },
      {
        'w-full': expanded
      }
    ]"
    :disabled="disabled"
    :type="type"
    v-on="$listeners"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "BButton",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "button"
    },
    variant: {
      type: String,
      default: "primary",
      validator: val => ["primary", "secondary"].indexOf(val) !== -1
    }
  }
};
</script>
