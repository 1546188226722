var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._g(_vm._b({staticClass:"px-4 py-2 | w-auto | transition-shadow duration-200 shadow-sm hover:shadow-md | inline-flex justify-center items-center | rounded-md | border | text-base sm:text-sm | font-medium | focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",class:[
    {
      'bg-gray-900 text-white border-black':
        _vm.variant === 'primary' && !_vm.disabled
    },
    {
      'bg-white hover:bg-gray-5 text-gray-600 hover:text-gray-700':
        _vm.variant === 'secondary' && !_vm.disabled
    },
    {
      'bg-gray-200 text-gray-400 line-through cursor-not-allowed': _vm.disabled
    },
    {
      'w-full': _vm.expanded
    }
  ],attrs:{"disabled":_vm.disabled,"type":_vm.type}},'button',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }