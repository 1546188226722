var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-hidden bg-white rounded shadow flex flex-grow flex-col text-gray-800 text-left h-full",class:{ detail: _vm.type === 'detail' }},[(_vm.imageValues.length)?_c('div',[_c('b-slideshow',{attrs:{"images":_vm.imageValues,"title":_vm.title.value}})],1):_vm._e(),_c('div',{staticClass:"h-full p-6 flex flex-col justify-between"},[_c('div',{staticClass:"whitespace-pre-wrap"},[_c('p',{staticClass:"font-semibold text-2xl"},[_vm._v(_vm._s(_vm.title.value))]),_c('p',{staticClass:"text-base text-gray-700"},[_vm._v(_vm._s(_vm.description.value))])]),_c('div',[(_vm.caption.value && _vm.caption.type === _vm.fieldTypes.URL)?_c('a',{staticClass:"block mt-4 text-blue-500 hover:text-blue-800",attrs:{"href":_vm.caption.value,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Visit "),_c('b-icon',{staticClass:"ml-2",attrs:{"name":"external-link-alt"}})],1):(_vm.caption.value)?_c('p',{staticClass:"mt-4 text-gray-600 text-sm"},[_vm._v(" "+_vm._s(_vm.caption.value)+" ")]):_vm._e(),(_vm.customFields.length && _vm.showCustomFields)?_c('ul',{staticClass:"mt-4"},_vm._l((_vm.customFields),function(field,index){return _c('li',{key:index,staticClass:"flex justify-between"},[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(field.name))]),(
              field.value &&
                (field.type === _vm.fieldTypes.URL ||
                  field.type === _vm.fieldTypes.IMAGE)
            )?_c('a',{staticClass:"text-blue-500 hover:text-blue-800",attrs:{"href":field.value,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_c('b-icon',{staticClass:"ml-2",attrs:{"name":"external-link-alt"}})],1):_c('span',{staticClass:"text-right"},[_vm._v(_vm._s(field.value))])])}),0):_vm._e(),_vm._t("bottom-content"),(_vm.isCheckoutAction && _vm.ctaValue)?_c('button',{staticClass:"w-full mt-4 bg-blue-500 hover:bg-blue-700 font-medium py-2 px-4 rounded text-center hover:shadow-md transition-shadow duration-300 focus:outline-none",style:({
          'background-color': _vm.cta.bgColor,
          color: _vm.cta.color
        }),on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }$event.preventDefault();return _vm.ctaAction($event)}}},[_vm._v(" "+_vm._s(_vm.cta.text)+" ")]):(_vm.ctaValue)?_c('a',{staticClass:"block mt-4 font-medium py-2 px-4 rounded text-center hover:shadow-md transition-shadow duration-300",style:({
          'background-color': _vm.cta.bgColor,
          color: _vm.cta.color
        }),attrs:{"href":_vm.ctaValue,"target":_vm.cta.download && _vm.cta.value ? '_self' : '_blank'},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(_vm.cta.text)+" ")]):_vm._e(),_vm._t("footer")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }